.header-title-mr {
  margin-top: 1rem;
  text-transform: capitalize;
  margin-left: 2rem;
}
.header-title-text {
  font-weight: 500;
  font-size: 13px;
  color: #b5b5c3;
}
.header-title__text {
  font-weight: 500;
  font-size: 13px;
  color: #3f4254;
}
.dash-icon {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
