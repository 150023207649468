.move-order {
  display: flex;
  flex-direction: column;
}
.move-order-flex {
  border-bottom: 1px solid #eff2f5;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.move-order-flex-name {
  font-weight: 700;
  font-size: 20px;
  color: #1d1d1b;
  margin-left: 1rem;
}
.move-order-flex-no {
  font-weight: 400;
  font-size: 20px;
  color: #1d1d1b;
  margin-left: 0.5rem;
}
.move-order-flex-col {
  border-bottom: 1px solid #eff2f5;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.move-order-flex-col-name {
  font-weight: 600;
  font-size: 15px;
  color: #1d1d1b;
  margin-left: 1rem;
  margin-top: 0.5rem;
}
.move-order-flex-col-info {
  font-weight: 500;
  font-size: 12px;
  color: #80808f;
  margin-left: 1rem;
  margin-top: 0.8rem;
  margin-bottom: 0.5rem;
}
.move-order-title {
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 1px solid #eff2f5;
}
.move-order-title- {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-right: 1rem;
}

.move-order-title- > span {
  font-weight: 500;
  font-size: 15px;
  color: #535353;
}
.move-order-title-search {
  width: 100%;
  height: 42px;
  background: #f4f7f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-left: 0.5rem;
}
.move-order-info {
  padding: 1rem;
  width: 100%;
  height: 50px;
  margin: auto;
  background: #fff9f4;
  border: 1px dashed #f89c47;
  border-radius: 6px;
  display: flex;
  align-items: center;
}
.move_order {
  padding: 0px 1rem;
}
.move-order-info > span {
  font-weight: 500;
  font-size: 15px;
  color: #7e8299;
  margin-left: 1rem;
}
.move-order-btn {
  width: 120px;
  height: 30px;
  background-color: #3699ff;
  border-radius: 6px;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
// ================ Success =============================
.success-alert {
  z-index: 2000;
}
.success-alert-popup {
  border-radius: 12px;
}
.success-alert-title {
  margin-top: 3rem;
  color: #1d1d1b;
}
.success-alert-btn {
  width: 120px;
  height: 30px;
  background-color: #3699ff;
  border: 0;
  border-radius: 6px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-bottom: 0;
}
.input-comp {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
