.page-loader {
  width: 50px;
  height: 50px;
  border: 7px solid #00aee1;
  border-bottom-color: transparent;
  border-radius: 50%;
  position: absolute;
  top: calc(30% - 25px);
  left: calc(50% - 25px);
  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.page-loader-small {
  height: 25px;
  width: 25px;
  border-width: 4px;
  top: calc(50% - 12.5px);
}

.page-loader-in-place {
  position: relative;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.dropdown-blue-loader {
  width: 15px;
  height: 15px;
  border: 2px solid #008fc3;
  border-bottom-color: transparent;
  border-radius: 60%;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.white-btn-loader {
  width: 15px;
  height: 15px;
  border: 2px solid white;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
