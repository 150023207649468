.error-404 {
    flex: 1;
    flex-direction: column;
    display: flex;
    padding: 1rem;
    width: 100%;
    background-color: #f5f8fa;
    height: 100vh;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}

.user-sel-none {
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
}

.user_sel_h1 {
    font-size: 50px;
    margin-bottom: 10%;
}

.user_sel_p {
    font-size: 14px;
    cursor: crosshair;
}

.user_sel_link {
    font-size: 19px;
    cursor: pointer;
    font-weight: 700;
    color: black;
}

.back-home {
    font-size: 19px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
}

.btn-none {
    background: transparent;
    border: 0px;
    outline: 0px;
    padding: 0px;
    margin: 0px;
}

.twoS-transition {
    transition: all 0.2s linear;
}
