table {
  font-family: "Roboto", sans-serif;
  font-style: normal;
}

.loader_center {
  position: relative;
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fcfcfc;
}

.diplayflex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0.4rem;
}

.diplayflex-col {
  display: flex;
  flex-direction: column;
}

// =====================================================================================================
.order-card {
  width: 100%;
  height: 110px;
  padding: 2.2rem 2rem;
  margin: 0.5rem;
  background: #cbf0f4;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.order-card>span {
  font-weight: 600;
  font-size: 16px;
  color: #181c32;
}

.order-card-flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 1rem;
}

.order-card__flex {
  display: flex;
  align-items: center;
}

.order-card__flex>span {
  color: #181c32;
  font-weight: 600;
  font-size: 13px;
}

.order-card__flex-row {
  display: flex;
  align-items: center;
  margin-left: 2rem;
}

.order-card__flex-row>span {
  color: #181c32;
  font-weight: 600;
  font-size: 13px;
}

// ===========================================================================

.issues-summary-card {
  width: 350px;
  height: 380px;
  margin: 0.5rem;
  background: #ffffff;
  border-radius: 12px;
  padding-top: 0.5rem;
}

.issues-summary-card-pd {
  padding: 2rem;
}

.issues-summary-card-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.issues-summary-card-title {
  display: flex;
  flex-direction: column;
}

.issues-summary-card__title {
  font-weight: 600;
  font-size: 17px;
  color: #464e5f;
}

.issues-summary-card-body {
  font-weight: 500;
  font-size: 12px;
  color: #80808f;
}

.issues-summary-card-light {
  font-weight: 550;
  font-size: 13px;
}

.issues-summary-card-flex-pre {
  width: 60px;
  height: 40px;
  background-color: #e1f0ff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.issues-summary-card-flex-pre>span {
  color: #3699ff;
  font-weight: 600;
  font-size: 16px;
}

.issues-summary-card-flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

.issues-summary-card-img {
  display: flex;
  flex-direction: row;
}

.issues-summary-card-text {
  font-weight: 500;
  font-size: 14px;
  color: #464e5f;
}

.issues-summary-card__text {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  justify-content: center;
}

.text-end {
  margin-right: 2.5rem;
}

.progress {
  border-radius: 5px;
}

.progress-bar {
  border-radius: 5px;
}

// ==========================================================================================
.search-criteria {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 12px;
  margin: 0.5rem;
}

.search-toggles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #eff2f5;
  padding: 2rem 2rem;
}

.search-criteria-header-title {
  font-weight: 700;
  font-size: 20px;
  color: #1d1d1b;
}

.search-criteria-pb {
  padding: 1rem 2rem;
  border-bottom: 2px solid #eff2f5;
}

// .search-criteria-flex {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
// }
.search-criteria-pb-col {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.search-criteria-pb-col>span {
  font-weight: 500;
  font-size: 15px;
  color: #535353;
}

.search-criteria-pb2 {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.search-criteria-pb2>span {
  font-weight: 500;
  font-size: 15px;
  color: #535353;
}

.search-criteria-pb1-search {
  width: 100%;
  height: 42px;
  background: #f4f7f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.search-criteria-pb1-search>input {
  margin-left: 1rem;
}

.search-criteria-pb1-search>img {
  margin-right: 1rem;
}

.search-criteria-pb2-search {
  width: 100%;
  height: 42px;
  background: #f4f7f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.search-criteria-pb2-search>input {
  margin-left: 1rem;
}

.search-criteria-pb2-search>img {
  margin-right: 1rem;
}

.search-criteria-pb3-search {
  width: 100%;
  height: 42px;
  background: #f4f7f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.search-criteria-pb3-search>input {
  margin-left: 1rem;
}

.search-criteria-pb3-search>img {
  margin-right: 1rem;
}

.search-criteria-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search-criteria-pb4-search {
  width: 100%;
  height: 42px;
  background: #f4f7f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.search-criteria-pb4-search>input {
  margin-left: 1rem;
}

.search-criteria-pb5-search {
  width: 100%;
  height: 42px;
  background: #f4f7f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-left: 1rem;
}

.search-criteria-pb5-search>input {
  margin-left: 1rem;
}

.search-criteria-pb6-search {
  width: 100%;
  height: 42px;
  background: #f4f7f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-left: 1rem;
}

.search-criteria-pb6-search>input {
  margin-left: 1rem;
}

.search-criteria-pb7-search {
  width: 100%;
  height: 42px;
  background: #f4f7f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.search-criteria-pb7-search__ {
  width: 100%;
  // background: #f4f7f8;
  border-radius: 6px;
  // display: flex;
  // flex-wrap: wrap;
  // align-items: center;
  margin-top: 0rem;
}

.search-criteria-pb7-search>input {
  margin-left: 1rem;
}

.search-criteria-pb7-search>img {
  margin-right: 1rem;
}

.search-criteria-pb8-search {
  width: 100%;
  height: 42px;
  background: #f4f7f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.search-criteria-pb8-search>input {
  margin-left: 1rem;
}

.search-criteria-pb8-search>img {
  margin-right: 1rem;
}

.search-criteria-pb9-search {
  width: 100%;
  height: 100%;
  background: #f4f7f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  border: none;
  flex-wrap: wrap;
}

.search-criteria-pb9-search>input {
  margin-left: 1rem;
  background-color: #3699ff;
  width: 900px;
}

.input_selection {
  background: #e5edf0;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  color: #1d1d1b;
  padding: 3px 6px;
  margin: 5px;
}

.input_selection_part {
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  color: #b5b5c3;
  padding: 3px 6px;
  margin: 5px;
}

.input_selection_img {
  margin: 0px 7px 0px 10px;
  user-select: none;
  z-index: 10;
}

.search-criteria-pb9-search>img {
  margin-right: 1rem;
}

.tag-auto {
  width: 800px;
}

.search-criteria-pb10-search {
  width: 100%;
  height: 42px;
  background: #f4f7f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.search-criteria-pb10-search>input {
  margin-left: 1rem;
}

.search-criteria-pb10-search>img {
  margin-right: 1rem;
}

.search-criteria-pb11-search {
  width: 100%;
  height: 42px;
  background: #f4f7f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.search-criteria-pb11-search>input {
  margin-left: 1rem;
}

.search-criteria-pb11-search>img {
  margin-right: 1rem;
}

.search-criteria-pb12-search {
  width: 100%;
  height: 42px;
  background: #f4f7f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.search-criteria-pb12-search>input {
  margin-left: 1rem;
  width: 10px;
}

.search-criteria-pb12-search>img {
  margin-right: 1rem;
}

.search-criteria-pb13-search {
  width: 100%;
  height: 42px;
  background: #f4f7f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.search-criteria-pb13-search>input {
  margin-left: 1rem;
  width: 10px;
}

.search-criteria-pb13-search>img {
  margin-right: 1rem;
}

.search-criteria-pb-mr {
  font-weight: 500;
  font-size: 16px;
  color: #535353;
  // margin-left: 1.5rem;
  // margin-right: 1.5rem;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search-criteria-footer {
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 8.5rem;
}

.search-criteria-footer-data {
  background-image: url("../../public/images/Total-Search-bg-img.svg");
  background-size: auto;
  background-color: #f5f8ff;
  border-radius: 12px;
  width: 410px;
  height: 50px;
  margin-left: 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.search-criteria-footer-title {
  font-weight: 500;
  font-size: 12px;
  color: #b5b5c3;
}

.search-criteria-footer__title {
  font-weight: 600;
  font-size: 20px;
  color: #464e5f;
}

.search-criteria-footer-btn {
  display: flex;
  align-items: center;
}

.search-criteria-footer-btn>span {
  font-weight: 500;
  font-size: 15px;
  color: #b5b5c3;
  margin-right: 2rem;
}

.search-criteria-footer-btn>button {
  min-width: 175px;
  max-width: fit-content;
  height: 34px;
  background: #3699ff;
  border-radius: 6px;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  border: 0px;
  margin-right: 1.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.part_no_select {
  width: 100%;
  height: 100%;
  // background: #f4f7f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  border: none;
  flex-wrap: wrap;
}

// ===================================================================================================
.orders-data {
  width: 100%;
  margin-left: 10px;
}

.priority {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 12px 12px 0px 0px;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  border-bottom: 1px solid #eff2f5;
  margin-left: 10px;
}

.priority_ {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.priority_flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.priority_flex-row-bold {
  font-weight: 600;
  font-size: 18px;
  color: #212121;
}

.priority_flex-row>img {
  margin-left: 1rem;
  cursor: pointer;
}

.priority_flex-row-text>span {
  font-weight: 500;
  font-size: 12px;
  color: #1d1d1b;
}

.priority_flex-row-text-subtitle {
  font-weight: 500;
  font-size: 14px;
  color: #1d1d1b;
}

.priority__ {
  display: flex;
  padding: 7px;
  background-color: #e5eaee;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}

.orders-info {
  width: 100%;
  margin-left: 10px;
  margin-right: auto;
  background-color: #f3f6f9;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 200ms height ease-in-out;
}

.orders-info .open {
  height: fit-content;
}

.panel-collapse {
  width: 100%;
  margin-left: 10px;
  margin-right: auto;
  background-color: #f3f6f9;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 500px;
  overflow: hidden;
  transition: max-height .5s ease-in;
}

.panel-close {
  max-height: 0px;
}

.orders-info-title {
  width: 100%;
  margin: 25px;
  // height: 100%;
  background: #ffffff;
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.orders-info-name {
  font-weight: 500;
  font-size: 16px;
}

.orders-info-pre {
  font-weight: 500;
  font-size: 12px;
  color: #80808f;
}

.orders-info-title_flex- {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orders-- {
  width: 1373px;
  height: 180px;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
  background-color: #ffffff;
  border-bottom: 1px solid #eff2f5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.orders---allstock {
  width: 255px;
  height: 119px;
  background-size: auto;
  border-radius: 6px;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.orders---round {
  width: 74px;
}

.orders---detail-name {
  display: flex;
  flex-direction: column;
}

.orders---detail-name>span {
  font-weight: 600;
  font-size: 14px;
}

.orders---detail-total {
  display: flex;
  flex-direction: row;
  margin-top: 0.8rem;
}

.orders---detail-total- {
  display: flex;
  flex-direction: column;
}

.orders---detail-total-1 {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.orders-dark {
  font-weight: 700;
  font-size: 12px;
  color: #80808f;
}

.orders-light {
  font-weight: 500;
  font-size: 12px;
  color: #80808f;
}

// == Table - Data ===
.table-info {
  // width: 1373px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.table-title {
  height: 50px;
  background-color: #fcfdfd;
  border-bottom: 2px solid #f3f6f9;
  // background-color: #f5f8fa;
  text-transform: uppercase;
}

.table-data {
  border-bottom: 2px solid #f3f6f9;
  background-color: #ffffff;
  // background-color: #f5f5f5;
  overflow: scroll;
}

.table-data:last-child {
  border-bottom: 0px solid transparent;
  background-color: #ffffff;
  // background-color: #f5f5f5;
  overflow: scroll;
}

.order-text1 {
  font-weight: 500;
  font-size: 16px;
  color: #7e8299;
}

.order-text2 {
  font-weight: 400;
  font-size: 16px;
  color: #7e8299;
}

.order-text3 {
  font-weight: 700;
  font-size: 16px;
  color: #1d1d1b;
}

.order-text4 {
  font-weight: 600;
  font-size: 14px;
  color: #1d1d1b;
}

.order-text5 {
  font-weight: 400;
  font-size: 13px;
  color: #f49600;
}

.order-text6 {
  font-weight: 500;
  font-size: 12px;
}

.order-text7 {
  font-weight: 500;
  font-size: 13px;
  color: #008fc3;
  cursor: pointer;
}

.shipping-box {
  width: 104px;
  height: 26px;
  background: #ffe2e5;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.stock-box__ {
  width: 80px;
  height: 26px;
  color: black;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // color: #f64e60;
}

.stock-box-red {
  width: 80px;
  height: 26px;
  background: #ffe2e5;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #f64e60;
}

.stock-box-green {
  width: 80px;
  height: 26px;
  background: #e8fff3;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #50cd89;
}

.view-text {
  font-weight: 400;
  font-size: 13px;
  color: #979797;
}

// =============================================================== Popup Box =====================================================

.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 760px;
  height: fit-content;
  border-radius: 12px;
}

.table-info-popup {
  width: 760px;
}

.c-p {
  cursor: pointer;
}

.popup-border {
  border-bottom: 1px solid #eff2f5;
}

.w65px {
  width: 65px;
}

.mt_20 {
  margin-top: 20%;
}

.mr_10px {
  margin-right: 10px;
}

.w500px {
  width: 500px;
}

.sort-ic {
  transform: rotate(180deg);
  transition: all 0.2s linear;
}