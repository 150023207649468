.redus {
  width: 93%;
  margin: auto;
}

.redus:hover {
  border-radius: 6px;
  background-color: #383845;
}

.asidemenuitem {
  width: 100%;
  margin: auto;
  /* margin-bottom: 5px; */
}

.menu-active {
  background-color: #383845;
  border-radius: 6px;
}

.side-menu_item-active_bg {
  background-color: #383845;
  cursor: pointer;
  user-select: none;
  border-radius: 0.5rem;
}

.side-menu_item-bg {
  background-color: transparent;
  cursor: pointer;
  user-select: none;
  border-radius: 0.5rem;
}