//
// Timeline Label
//


.timeline-label {
	position: relative;

	$label-width: 50px;

	&:before {
		content: '';
		position: absolute;
		left: $label-width + 1px;
		width: 3px;
		top: 0;
		bottom: 0;
		background-color: var(--kt-gray-200);
	}

	// 
	.timeline-item {
		display: flex;
		align-items: flex-start;
		position: relative;
		margin-bottom: 1.7rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.timeline-label {
		width: $label-width;
		flex-shrink: 0;
		position: relative;
		color: var(--kt-gray-800);
	}

	.timeline-badge {
		flex-shrink: 0;
		background-color: var(--kt-body-bg);
		width: 1rem;
		height: 1rem;
		border-radius: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1;
		position: relative;
		margin-top: 1px;
		margin-left: -0.5rem;
		padding: 3px !important;
		border: 6px solid var(--kt-body-bg) !important;

		span {
			display: block;
			border-radius: 100%;
			width: 6px;
			height: 6px;
			background-color: var(--kt-gray-200);
		}
	}

	.timeline-content {
		flex-grow: 1;
	}
}